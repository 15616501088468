<template>
  <div>
    <PageHeader>
      <template slot="right">
        <label class="d-inline-flex align-items-center">
          <b-form-checkbox
            v-model="toggleStatus"
            switch
          >
            ดึงข้อมูลอัตโนมัติ (ทุก
            {{ intervalTime / 1000 }} วินาที)
          </b-form-checkbox>
        </label>
      </template>
    </PageHeader>
    <b-button-group class="mb-2" >
      <b-button
        v-for="item in menu"
        :key="item.name"
        :variant="currentMenu === item.name ? '' : 'light'"
        @click="currentMenu = item.name"
      >
        {{ item.label }}
        <b-badge
          v-if="item.name === 'pending'"
          variant="danger"
          class="float-right ml-2"
        >
          {{ +unmatchedTotal === +selectedLimit ? unmatchedTotal + "+" : unmatchedTotal }}
        </b-badge>
        <b-badge
            v-if="item.name === 'done'"
            variant="danger"
            class="float-right ml-2"
        >
          {{ +doneTotal === +selectedLimit ? doneTotal + "+" : doneTotal }}
        </b-badge>
      </b-button>
    </b-button-group>
    <div>
      <component :is="selectedComponent" :type="'deposit'" @on-agent-change="onAgentIdChange" @on-master-change="onMasterIdChange" />
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'

export default {
  page: {
    title: 'รายการฝาก'
  },
  components: {
    PendingTransactions: () => import('./components/pending.vue'),
    DoneTransactions: () => import('./components/done.vue'),
  },
  data() {
    return {
      toggleStatus: true,
      currentMenu: 'pending',
      menu: [ {name: 'pending', label: 'รอดำเนินการ'}, {name:'done', label: 'ดำเนินการแล้ว'} ],
      currentPage: 1,
      selectedLimit: 20,
      selectedMaster: '',
      selectedAgent: '',
      selectedNote: '',
      intervalIdentifier: null,
      intervalTime: 10000
    }
  },
  computed: {
    ...mapGetters(['isAgent', 'depositDone', 'depositUnmatched']),
    ...mapState({
      isGettingMe: (state) => state.user.isGettingMe,
      intervalFetch: (state) => state.credit.intervalFetch,
    }),
    unmatchedTotal() {
      return this.depositUnmatched?.items?.length || 0
    },
    doneTotal() {
      return this.depositDone?.items?.length || 0
    },
    selectedComponent() {
      switch (this.currentMenu) {
        case 'pending':
          return 'PendingTransactions'
        case 'done':
          return 'DoneTransactions'
        default:
          return ''
      }
    },
  },
  watch: {
    toggleStatus(val) {
      if (val) {
        this.startInterval()
      } else {
        this.stopInterval()
      }
    },
    isAgent(val) {
      if(val) {
        this.fetchData()
      }
    }
  },
  mounted() {
    this.fetchData()
    this.startInterval();
  },
  beforeDestroy() {
    this.stopInterval()
  },
  methods: {
    ...mapActions([
      'fetchDepositUnmatched',
    ]),
    onAgentIdChange(agentId) {
      this.selectedAgent = agentId
    },
    onMasterIdChange(masterId) {
      this.selectedMaster = masterId
    },
    startInterval() {
      this.intervalIdentifier = setInterval(() => {
        if(this.isAgent || this.selectedAgent.length) {
          this.fetchDepositUnmatched({
            limit: this.selectedLimit,
            page: this.currentPage,
            search: this.search,
            agentId: this.selectedAgent,
            masterId: this.selectedMaster,
          })
        }
      }, this.intervalTime)
    },
    stopInterval() {
      clearInterval(this.intervalIdentifier)
    },
    fetchData() {
      if(this.isAgent) {
        this.fetchDepositUnmatched({
          limit: this.selectedLimit,
          page: this.currentPage,
          search: '',
          agentId: '',
          masterId: '',
        })
      }
    },
  },
}
</script>
